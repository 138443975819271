@import url(https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','Nanum Pen Script', 'cursive', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Cantarell", sans-serif;
}

.hero {
  position: relative;
  width: 100%;
  height: 100vh;
}

.hero__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.hero__title {
  position: absolute;
  bottom: 15%;
  right:10%;
  color: #fff;
  font-size: 4rem;
  padding: 15px;
  font-family: 'Nanum Pen Script';
}




@media screen and (max-width: 800px) {
  .hero__title {
    font-size: 2.5rem;
  };

}

@media screen and (max-width: 400px) {
  .hero__title {
    font-size: 2rem;
  };

}

.navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  height: 70px;
  background-color: #000;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px 0px 30px;
  margin-bottom: -70px;
  z-index: 5;
}

.navbar__logo {
  color: white;
  font-size: 2.2rem;
  font-family: 'Nanum Pen Script';
}



/*
  This is the container for all the navbar links ( <ul> )
  */
.navbar__list {
  display: flex;
  list-style: none;
}

/*
  This is a single item ( <li> ) in the list
  */
.navbar__item {
  white-space: nowrap;
  cursor: pointer;
}

/*
  The anchor tag ( <a> ) for the navbar links
  */
.navbar__link {
  font-size: 0.85rem;
  text-decoration: none;
  color: rgb(255, 255, 255);
  text-align: center;
  padding: 10px;
  margin: 0px 5px;
  border-style: solid;
  border-width: 1.5px;
  border-color: transparent;
  border-radius: 3px;
  transition: 250ms;
}

.navbar__link:hover {
  border-color: white;
  transition: 250ms;
}

.navbar__menu {
  color: white;
  display: none;
  cursor: pointer;
}

@media screen and (max-width: 800px) {

  .navbar {
    opacity: 1;
  }

  .navbar__list {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    top: 70px;
    left: -100%;
    width: 100%;
    height: 300px;
    position: absolute;
    transition: all 0.5s ease;
  }

  .navbar__list--active {
    left: 0;
  }

  .navbar__menu {
    display: block;
  }

  .navbar__item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    flex: 1 1;
  }

  .navbar__link {
    display: flex;
    height: 100%;
    width: 100vw;
    justify-content: center;
    align-items: center;
    margin: 0px;
    border-width: 0px;
    background-color: black;
  }

  .navbar__link:hover {
    border-width: 1px;
  }

}

.slider {
  display: flex;
  align-items: center;
  margin: 20px;

  opacity: 0;
  -webkit-transform: scale(85%);
          transform: scale(85%);
  transition: 2s;
}

.slider--zoom {
  opacity: 1;
  -webkit-transform: scale(100%);
          transform: scale(100%);
  transition: 2s;
}

.slider__image {
  width: 50%;
}

.slider__content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-size: 1rem;
}

.slider__title-about {
  text-align: center;
  font-family: 'Nanum Pen Script';
  font-size: 2.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.slider__description {
  text-align: left;
  padding: 1rem;
  line-height: 1.5rem;
}

.slider__description-about {
  text-align: left;
  padding: 1rem 1rem .1rem 1rem;
  line-height: 1.5rem;
}

.span {
  font-weight: bold;
  text-transform: uppercase;
}


.link--email {
  text-decoration: none;
}


.link:link {
  font-style: normal;
  text-decoration: none;
  color: rgb(255, 0, 106);
}

.link:active {
  color: rgb(255, 0, 106);
}

.link:visited {
  color: rgb(77, 133, 243);
}

.link:hover{
  color: rgb(255, 0, 106);
}



@media screen and (max-width: 800px) {
  .slider {
    flex-direction: column;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 5px;
  }

  .slider__content {
    font-size: 0.9rem;
  }

  .slider__image {
    width: 100%;
  }

  .slider__title-about {
    font-size: 2rem;
  }
}

@media screen and (max-width: 400px) {
  .slider__content {
    font-size: 0.8rem;
  }
}


.map-slider{

    align-items: center;
  
    opacity: 0;
    /* transform: scale(92%); */
    transition: 2s;
  }
  
  .map-slider--zoom{
    opacity: 1;
    /* transform: scale(100%); */
    transition: 2s;
  }
  
  .slider__map {
    width: 100vw;
  }

  .divider--container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 8rem;
    padding-bottom: 1.5rem;
    opacity: 1;
    transition: 0.2s;

}

.divider {
    font-family: 'Nanum Pen Script';
    font-size: 2.5rem;
}

@media screen and (max-width: 800px) {
    .divider {
        font-size: 2rem;
    }
}

  
  @media screen and (max-width: 800px) {
    .map-slider {
      flex-direction: column;
      background-color: rgb(252, 252, 242);
    }
  
    .slider__map {
      width: 100%;
    }

  }
  
.slider {
    display: flex;
    align-items: center;
    margin: 20px;
  
    opacity: 0;
    -webkit-transform: scale(85%);
            transform: scale(85%);
    transition: 2s;
  }
  
  .slider--zoom {
    opacity: 1;
    -webkit-transform: scale(100%);
            transform: scale(100%);
    transition: 2s;
  }
  
  .slider__image {
    width: 50%;
  }
  
  .slider__content {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding: 20px;
    text-align: center;
    font-size: 1rem;
    line-height: 1.6rem;
  }
  
  .slider__title {
    text-align: center;
    font-family: 'Nanum Pen Script';
    font-size: 2.5rem;
    padding-top: 2rem;
  }
  
  .slider__description-video {
    text-align: left;
    list-style-type: none;
    padding-top: 2.5rem;
    padding-bottom: 0.5rem;
  }

  .slider__description-video-note {
    list-style-type: none;
    text-align: left;
    font-size: 0.8rem;
  }

  .video-slider__list {
    text-align: left;
  }

  .video-slider__list li {
    list-style-type: none;
  }

  .span {
    font-weight: bold;
    text-transform: uppercase;
  }

  .link:link {
    font-style: normal;
    text-decoration: none;
    color: rgb(255, 0, 106);
  }
  
  .link--email {
    text-decoration: none;
  }
  
  
  .link:visited {
    color: rgb(77, 133, 243);
  }
  
  .link:hover{
    color: rgb(255, 0, 106);
  }

  .link {
    font-size: 0.85rem;
  }
  
  @media screen and (max-width: 1000px) {
    .slider__content {
      font-size: 0.9rem;
    }
  }

  @media screen and (max-width: 800px) {
    .slider {
      flex-direction: column;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      border-radius: 5px;
      /* border: 1px solid rgb(255, 217, 19); */
    }

    .slider__description-video-note {
      font-size: 0.7rem;
    }
    

    .sm {
      padding-bottom: 3rem;
    }

    .slider__title {
      font-size: 2rem;
    }


    .slider__image {
      width: 100%;
    }
  }

  @media screen and (max-width: 400px) {
    .slider__content {
      font-size: 0.8rem;
    }
  }

  
.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 2rem;
    gap: 2rem;
    font-size: 0.7rem;
    background-color: #000;
    color: #fff;
    height: 2rem;
}

.link--footer {
    font-style: normal;
    text-decoration: none;
    color: #fff;
  }

  .link--footer:active {
    color: rgb(77, 133, 243)
  }
  
  .link--footer:visited {
    -webkit-text-decoration: underlines;
            text-decoration: underlines;
  }
  
  .link--footer:hover{
    color: rgb(77, 133, 243);
  }

