.slider {
  display: flex;
  align-items: center;
  margin: 20px;

  opacity: 0;
  transform: scale(85%);
  transition: 2s;
}

.slider--zoom {
  opacity: 1;
  transform: scale(100%);
  transition: 2s;
}

.slider__image {
  width: 50%;
}

.slider__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-size: 1rem;
}

.slider__title-about {
  text-align: center;
  font-family: 'Nanum Pen Script';
  font-size: 2.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.slider__description {
  text-align: left;
  padding: 1rem;
  line-height: 1.5rem;
}

.slider__description-about {
  text-align: left;
  padding: 1rem 1rem .1rem 1rem;
  line-height: 1.5rem;
}

.span {
  font-weight: bold;
  text-transform: uppercase;
}


.link--email {
  text-decoration: none;
}


.link:link {
  font-style: normal;
  text-decoration: none;
  color: rgb(255, 0, 106);
}

.link:active {
  color: rgb(255, 0, 106);
}

.link:visited {
  color: rgb(77, 133, 243);
}

.link:hover{
  color: rgb(255, 0, 106);
}



@media screen and (max-width: 800px) {
  .slider {
    flex-direction: column;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 5px;
  }

  .slider__content {
    font-size: 0.9rem;
  }

  .slider__image {
    width: 100%;
  }

  .slider__title-about {
    font-size: 2rem;
  }
}

@media screen and (max-width: 400px) {
  .slider__content {
    font-size: 0.8rem;
  }
}

