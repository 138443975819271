.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    font-size: 0.7rem;
    background-color: #000;
    color: #fff;
    height: 2rem;
}

.link--footer {
    font-style: normal;
    text-decoration: none;
    color: #fff;
  }

  .link--footer:active {
    color: rgb(77, 133, 243)
  }
  
  .link--footer:visited {
    text-decoration: underlines;
  }
  
  .link--footer:hover{
    color: rgb(77, 133, 243);
  }
