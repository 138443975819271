.hero {
  position: relative;
  width: 100%;
  height: 100vh;
}

.hero__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.hero__title {
  position: absolute;
  bottom: 15%;
  right:10%;
  color: #fff;
  font-size: 4rem;
  padding: 15px;
  font-family: 'Nanum Pen Script';
}




@media screen and (max-width: 800px) {
  .hero__title {
    font-size: 2.5rem;
  };

}

@media screen and (max-width: 400px) {
  .hero__title {
    font-size: 2rem;
  };

}
